import React, { useEffect }        from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SEO         from '@interness/web-core/src/components/modules/SEO/SEO';

const RolexPage = () => {
  const data = useStaticQuery(query);
  const [scriptLoading, setScriptLoading] = React.useState(true);
  const rolexInfo = data.directusBrandConfig.brand_config[0];

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://rlx-bespoke-light.hosting.interness.de/lib/rlx-wc.js";
    script.type = 'module';
    document.body.appendChild(script);

    script.onload = () => {
      setScriptLoading(false);
    };

  }, []);

  return (
    <>
      <SEO title={'Rolex'}
           description={'Entdecken Sie bei Juwelier Hohage in Lüdenscheid Rolex & Tudor Uhren, aktuellen Schmuck und Eheringe und perfekten Service in unserer Meisterwerkstatt'}/>
      {!scriptLoading && <rlx-app apikey={rolexInfo.rolex_api_key} pathroot="/uhren/rolex"/>}
    </>
  )
};

export default RolexPage;

const query = graphql`
    query {
        directusCompany {
            display_name
        }
        directusBrandConfig(id: {ne: "dummy"}) {
            brand_config {
                rolex_api_key
                rolex_legal_url
                rolex_version
            }
        }
    }
`;
